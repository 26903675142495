import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'
import { type ComponentMaintenanceReserveRate, type LandingGearDto, type LandingGearMinimumReDeliveryCondition } from '@flyward/assets/models'
import {
  BooleanCellWithLabel,
  CheckTypeDisplay,
  formatNumber,
  MaintenanceReserveCollectionTypeDisplay,
  MinimumReDeliveryConditionTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { isNil } from 'lodash'
import { useState, useEffect } from 'react'

interface ILGContractualReadOnlyTabProps {
  component: LandingGearDto
}

export const LGContractualReadOnlyTab = ({ component }: ILGContractualReadOnlyTabProps) => {
  const maintenanceReserve = component.landingGearContract.landingGearMaintenanceReserveContract
  const landingGearContract = component.landingGearContract
  const rates = [maintenanceReserve.landingGearMaintenanceReserveRate]
  const contractDeliverySnapshot = component.landingGearContract.contractDeliverySnapshot
  const minimumRedeliveryConditions = component.landingGearContract.minimumReDeliveryConditions
  const [dynamicLabel, setDynamicLabel] = useState<string>('')

  useEffect(() => {
    setDynamicLabel(getLabelContent(maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive))
  }, [maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive])

  const Rates = rates.map((rate: ComponentMaintenanceReserveRate) => {
    if (isNil(rate)) return null
    return (
      <div key={rate.checkType} className="flex w-full gap-x-6 gap-y-4">
        <BooleanCellWithLabel
          className="basis-1/6"
          label={`MR active`}
          info={component.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate`}
          info={rate.rateAmount}
          preventInfo={
            !component.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive &&
            !component.landingGearContract.landingGearMaintenanceReserveContract.isEolActive
          }
          currencyDecimals={2}
          displayType={NumberDisplayType.Currency}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate type`}
          info={UtilizationUnitsDisplay(rate.utilizationUnit)}
          preventInfo={
            !component.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive &&
            !component.landingGearContract.landingGearMaintenanceReserveContract.isEolActive
          }
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate year`}
          info={rate.ratesYear}
          displayType={NumberDisplayType.Year}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} MR collection type`}
          info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
          preventInfo={!component.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`Current ${CheckTypeDisplay(rate.checkType)} MR fund`}
          info={rate.currentFund}
          preventInfo={!component.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
          displayType={NumberDisplayType.CurrencyRounded}
        />
      </div>
    )
  })

  const minimumRedeliveryConditionsDisplay: string = minimumRedeliveryConditions
    ?.map((minimumRedeliveryCondition: LandingGearMinimumReDeliveryCondition) => {
      return `${MinimumReDeliveryConditionTypeDisplay(
        minimumRedeliveryCondition.minimumReDeliveryConditionType,
      )} - ${formatNumber(minimumRedeliveryCondition.value)} ${UtilizationUnitsDisplay(minimumRedeliveryCondition.minimumReDeliveryConditionUnit)}`
    })
    .join('\n')
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full flex-col gap-x-6 gap-y-2">{Rates}</div>
      <div className="flex w-full gap-x-1">
        <BooleanCellWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          info={landingGearContract.landingGearMaintenanceReserveContract.isEolActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`Overhaul ${dynamicLabel} Escalation`}
          info={landingGearContract.escalations?.maintenanceReserveAgreedEscalationPercentage}
          preventInfo={
            !component.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive &&
            !component.landingGearContract.landingGearMaintenanceReserveContract.isEolActive
          }
          displayType={NumberDisplayType.Percentage}
        />
        <NumberCellWithLabel
          key={contractDeliverySnapshot.checkType}
          className="basis-1/6"
          label={`Months since ${CheckTypeDisplay(contractDeliverySnapshot.checkType)} at contract delivery`}
          info={contractDeliverySnapshot.monthsSinceEventAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <div className="-mr-6 basis-3/6" />
      </div>
      <div className="flex gap-x-6">
        <TextCellWithLabel className="mr-12 basis-3/6 whitespace-pre" label="Redelivery Conditions" info={minimumRedeliveryConditionsDisplay} />
        <div className="basis-1/6" />
      </div>
    </div>
  )
}
