import { type AircraftEngineDto } from '@flyward/assets/models'
import { EditableDateCellWithLabelWithLabel, EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { type Control, type UseFormSetValue } from 'react-hook-form'

export interface IEngineDetailsGeneralEditable {
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
}

export const EngineDetailsGeneralEditable = ({ setFormValue, formControl }: IEngineDetailsGeneralEditable) => {
  return (
    <div className="flex gap-x-6">
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Text}
        className="basis-1/6"
        label="ESN"
        formControl={formControl}
        fieldName={`componentSerialNumber`}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Text}
        className="basis-1/6"
        label="Manufacturer"
        formControl={formControl}
        fieldName={`manufacturingDetail.manufacturer`}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Text}
        className="basis-1/6"
        label="Engine Model"
        formControl={formControl}
        fieldName={`componentModel`}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Percentage}
        className="basis-1/6"
        label="Derate"
        formControl={formControl}
        fieldName={`deratePct`}
      />
      <EditableDateCellWithLabelWithLabel
        className="basis-1/6"
        label={`DOM`}
        formControl={formControl}
        fieldName={`manufacturingDetail.dom`}
        allowClear={false}
        setFormValue={setFormValue}
      />
      <div className="basis-1/6" />
    </div>
  )
}
