import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'
import { type AirframeDto, type ComponentMaintenanceReserveRate } from '@flyward/assets/models'
import { type ContractDeliverySnapshotDto } from '@flyward/assets/models/contract/ContractDeliverySnapshotDto'
import {
  BooleanCellWithLabel,
  CheckTypeDisplay,
  type ComponentMinimumReDeliveryConditionDto,
  formatNumber,
  MaintenanceReserveCollectionTypeDisplay,
  MinimumReDeliveryConditionTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { cloneDeep, isNil } from 'lodash'
import { useEffect, useState } from 'react'

interface IAirframeContractualReadOnlyTabProps {
  airframe: AirframeDto
}

const ContractDeliverySnapshot = ({ contractDeliverySnapshots }: { contractDeliverySnapshots: ContractDeliverySnapshotDto[] }) => {
  const missingRows = 6 - contractDeliverySnapshots.length

  return (
    <div className="flex w-full gap-x-6 gap-y-4">
      {contractDeliverySnapshots?.map((contractDeliverySnapshot, index) => {
        if (isNil(contractDeliverySnapshot)) return null
        return (
          <NumberCellWithLabel
            key={index}
            className="basis-1/6"
            label={`Months since ${CheckTypeDisplay(contractDeliverySnapshot.checkType)} check at contract delivery`}
            info={contractDeliverySnapshot.monthsSinceEventAtContractDelivery}
            displayType={NumberDisplayType.Integer}
          />
        )
      })}
      {[...Array(missingRows)].map((_, index) => (
        <div key={index} className="mr-2 basis-1/6" />
      ))}
    </div>
  )
}

export const AirframeContractualReadOnlyTab = ({ airframe }: IAirframeContractualReadOnlyTabProps) => {
  const maintenanceReserve = airframe.airframeContract.airframeMaintenanceReserveContract
  const rates = maintenanceReserve.airframeMaintenanceRates
  const minimumRedeliveryConditions = airframe.airframeContract.minimumReDeliveryConditions
  const contractDeliverySnapshots = airframe.airframeContract.contractDeliverySnapshots
  const [dynamicLabel, setDynamicLabel] = useState<string>('')

  useEffect(() => {
    setDynamicLabel(getLabelContent(maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive))
  }, [maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive])

  const Rates = cloneDeep(rates)
    .sort((a, b) => Number(a.checkType) - Number(b.checkType))
    .map((rate: ComponentMaintenanceReserveRate) => {
      if (isNil(rate)) return null
      return (
        <div key={rate.checkType} className="flex w-full gap-x-6 gap-y-4">
          <NumberCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate`}
            info={rate.rateAmount}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
            displayType={NumberDisplayType.Currency}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate type`}
            info={UtilizationUnitsDisplay(rate.utilizationUnit)}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate year`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
            info={rate.ratesYear}
            displayType={NumberDisplayType.Year}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} MR collection type`}
            info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`Current ${CheckTypeDisplay(rate.checkType)} MR fund`}
            info={rate.currentFund}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
            displayType={NumberDisplayType.CurrencyRounded}
          />
          <div className="mr-6 basis-1/6" />
        </div>
      )
    })

  const minimumRedeliveryConditionsDisplay: string = minimumRedeliveryConditions
    ?.map((minimumRedeliveryCondition: ComponentMinimumReDeliveryConditionDto) => {
      return `${MinimumReDeliveryConditionTypeDisplay(
        minimumRedeliveryCondition.minimumReDeliveryConditionType,
      )} - ${formatNumber(minimumRedeliveryCondition.value)} ${UtilizationUnitsDisplay(minimumRedeliveryCondition.minimumReDeliveryConditionUnit)}`
    })
    .join('\n')

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-6">
        <BooleanCellWithLabel className="basis-1/6" label="MR Active" info={maintenanceReserve.isMaintenanceReserveActive} />
        <BooleanCellWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          info={airframe.airframeContract?.airframeMaintenanceReserveContract?.isEolActive}
        />

        <NumberCellWithLabel
          className="basis-1/6"
          label={`${dynamicLabel} Escalation`}
          info={airframe.airframeContract.escalations?.maintenanceReserveAgreedEscalationPercentage}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          displayType={NumberDisplayType.Percentage}
        />
        <div className="mr-18 basis-3/6 " />
      </div>
      <div className="flex w-full flex-col gap-y-2">
        <ContractDeliverySnapshot contractDeliverySnapshots={contractDeliverySnapshots} />
      </div>
      <div className="flex w-full flex-col gap-y-2">{Rates}</div>
      <div className="flex gap-x-6">
        <TextCellWithLabel className="basis-2/6 whitespace-pre" label="Redelivery Conditions" info={minimumRedeliveryConditionsDisplay} />
        <div className="mr-12 basis-2/6" />
      </div>
    </div>
  )
}
