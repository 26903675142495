import { cloneDeep, isNil } from 'lodash'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type EngineUnitInfo } from '@flyward/platform/models/DTOs/EngineUnitInfo'
import { CheckLimitType } from '@flyward/knowledgeBase'
import { NumberCellWithLabel, NumberDisplayType } from '@flyward/platform/components'

interface IReadonlyEditLimits {
  flyForwardParameters: FlyForwardParametersDTO
}

const ReadonlyEditLimits = ({ flyForwardParameters }: IReadonlyEditLimits) => {
  let enginesDetails: EngineUnitInfo[] = []

  enginesDetails = flyForwardParameters.commonAssetDetails!.engineUnitInfos

  enginesDetails = cloneDeep(enginesDetails).sort((a, b) => {
    if (a.engineUnitSerialNumber < b.engineUnitSerialNumber) return -1
    if (a.engineUnitSerialNumber > b.engineUnitSerialNumber) return 1
    return 0
  })

  return (
    <div className="flex flex-col pt-4">
      {enginesDetails.map((engineDetails) => {
        const eprChecks = engineDetails.eprKbChecks
        const currentFirstRunLimit = eprChecks.find((c) => !isNil(c.eprCheckLimitType) && c.eprCheckLimitType === CheckLimitType.FirstRun)!
        const currentMatureLimit = eprChecks.find((c) => !isNil(c.eprCheckLimitType) && c.eprCheckLimitType === CheckLimitType.Mature)!

        const FirstRunInput = (
          <NumberCellWithLabel
            key={`${engineDetails.engineUnitId}-limit-first-run`}
            label="First Run Limit"
            info={currentFirstRunLimit?.limitAmount}
            className="w-42"
            displayType={NumberDisplayType.Integer}
          />
        )

        const MatureRunInput = (
          <NumberCellWithLabel
            key={`${engineDetails.engineUnitId}-limit-mature`}
            label="Mature Limit"
            info={currentMatureLimit?.limitAmount}
            className="w-42"
            displayType={NumberDisplayType.Integer}
          />
        )

        return (
          <div key={engineDetails.assetId + engineDetails.engineUnitId} className="flex items-center gap-x-6 py-4">
            <div>
              <p className="flex pb-2 text-base font-semibold text-gray-700">Engine {engineDetails.engineUnitSerialNumber} Limits</p>
              <div className="flex gap-x-6">
                {FirstRunInput}

                {MatureRunInput}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export { ReadonlyEditLimits }
