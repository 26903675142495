import { type StandAloneEngineDTO } from '@flyward/assets/models'
import { ComponentContainer, EnvironmentalImpactTypeDisplay, ErrorBoundary, formatDate, TextCellWithLabel } from '@flyward/platform'

interface IEditStandaloneEngineGeneralReadOnlyProps {
  standAloneEngine: StandAloneEngineDTO
}

export const StandaloneEngineReadonlyDetails = ({ standAloneEngine }: IEditStandaloneEngineGeneralReadOnlyProps) => {
  return (
    <ComponentContainer className="p-6">
      <ErrorBoundary>
        <div className="flex w-full flex-col">
          <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">General Information</p>
          <div className="flex flex-col gap-x-6 gap-y-2">
            <div className="flex gap-x-6">
              <TextCellWithLabel className="basis-1/6" label="Operator" info={standAloneEngine.lessee} />
              <TextCellWithLabel className="basis-1/6" label="Lessee Habitual Base" info={standAloneEngine.lesseeHabitualBase} />
              <TextCellWithLabel className="basis-1/6" label="Lease Start" info={formatDate(standAloneEngine.leaseStartDate)} />
              <TextCellWithLabel className="basis-1/6" label="Lease End" info={formatDate(standAloneEngine.leaseEndDate)} />
              <TextCellWithLabel className="basis-1/6" label="Region" info={standAloneEngine.region} />
              <TextCellWithLabel className="basis-1/6" label="Portfolio" info={standAloneEngine.portfolio} />
            </div>
            <div className="flex gap-x-6">
              <TextCellWithLabel className="basis-1/6" label="Tech Spec. Date" info={formatDate(standAloneEngine.dateOfLastTechSpec)} />
              <TextCellWithLabel
                className="basis-1/6"
                label="Operating Environment"
                info={EnvironmentalImpactTypeDisplay(standAloneEngine.operatingEnvironment)}
              />
              <div className="mr-18 basis-4/6"></div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </ComponentContainer>
  )
}
