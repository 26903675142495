import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'
import { RedeliveryConditionsEditable } from '@flyward/assets/components/AircraftComponents/common'
import { type AirframeDto, type ComponentMaintenanceReserveRate } from '@flyward/assets/models'
import {
  CheckTypeDisplay,
  EditableBooleanCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  InputType,
  isArrayEmptyOrNull,
  MaintenanceReserveCollectionTypeDisplay,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { cloneDeep, isNil } from 'lodash'
import { useState, useEffect } from 'react'
import { type Control, type FormState, type UseFormSetValue, type UseFormWatch } from 'react-hook-form'
import { type ContractDeliverySnapshotDto } from '@flyward/assets/models/contract/ContractDeliverySnapshotDto'

interface IAirframeContractualEditableTabProps {
  airframe: AirframeDto
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
  watchFields: UseFormWatch<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
}

const ContractDeliverySnapshot = ({
  contractDeliverySnapshots,
  formControl,
}: {
  contractDeliverySnapshots: ContractDeliverySnapshotDto[]
  formControl: Control<AirframeDto, unknown>
}) => {
  const missingRows = 6 - contractDeliverySnapshots.length
  return (
    <div className="flex w-full gap-x-6 gap-y-4">
      {contractDeliverySnapshots?.map((contractDeliverySnapshot, index) => {
        if (isNil(contractDeliverySnapshot)) return null
        return (
          <EditableInputCellWithLabelWithLabel
            key={index}
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label={`Months since ${CheckTypeDisplay(contractDeliverySnapshot.checkType)} check at contract delivery`}
            formControl={formControl}
            fieldName={`airframeContract.contractDeliverySnapshots.${index}.monthsSinceEventAtContractDelivery`}
          />
        )
      })}
      {[...Array(missingRows)].map((_, index) => (
        <div key={index} className="mr-2 basis-1/6" />
      ))}
    </div>
  )
}

export const AirframeContractualEditableTab = ({ airframe, formControl }: IAirframeContractualEditableTabProps) => {
  const maintenanceReserve = airframe.airframeContract.airframeMaintenanceReserveContract
  const rates = maintenanceReserve.airframeMaintenanceRates
  const minimumRedeliveryConditions = airframe.airframeContract.minimumReDeliveryConditions
  const contractDeliverySnapshots = airframe.airframeContract.contractDeliverySnapshots
  const [dynamicLabel, setDynamicLabel] = useState<string>('')

  useEffect(() => {
    setDynamicLabel(getLabelContent(maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive))
  }, [maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive])

  const Rates = cloneDeep(rates)
    .sort((a, b) => Number(a.checkType) - Number(b.checkType))
    .map((rate: ComponentMaintenanceReserveRate, index) => {
      if (isNil(rate)) return null
      return (
        <div key={rate.checkType} className="flex w-full gap-x-6 gap-y-4">
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.Decimal}
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate`}
            formControl={formControl}
            fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${index}.rateAmount`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate type`}
            info={UtilizationUnitsDisplay(rate.utilizationUnit)}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate year`}
            formControl={formControl}
            fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${index}.ratesYear`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} MR collection type`}
            info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label={`Current ${CheckTypeDisplay(rate.checkType)} MR fund`}
            formControl={formControl}
            fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${index}.currentFund`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <div className="mr-6 basis-1/6" />
        </div>
      )
    })

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-6">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Active"
          formControl={formControl}
          disabled={isArrayEmptyOrNull(airframe.airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates)}
          fieldName={`airframeContract.airframeMaintenanceReserveContract.isMaintenanceReserveActive`}
        />
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          formControl={formControl}
          disabled={isArrayEmptyOrNull(airframe.airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates)}
          fieldName={`airframeContract.airframeMaintenanceReserveContract.isEolActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label={`${dynamicLabel} Escalation`}
          formControl={formControl}
          fieldName={`airframeContract.escalations.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <div className="mr-18 basis-3/6 " />
      </div>
      <div className="flex w-full flex-col gap-y-2">
        <ContractDeliverySnapshot contractDeliverySnapshots={contractDeliverySnapshots} formControl={formControl} />
      </div>
      <div className="flex w-full flex-col gap-y-2">{Rates}</div>
      <div className="flex gap-x-6">
        <RedeliveryConditionsEditable
          label="Redelivery Conditions"
          fieldPath="airframeContract.minimumReDeliveryConditions"
          formControl={formControl}
          conditions={minimumRedeliveryConditions}
        />
        <div className="mr-12 basis-2/6" />
      </div>
    </div>
  )
}
