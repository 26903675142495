import { Label } from '../../Label'
import { Button, ButtonVariant } from '../../Button'

export const CellWithButton = ({ label, info, onClick }: { label: string; info: string; onClick?: () => void }) => {
  const id = `${label}-info-button`

  return (
    <div className="flex flex-col justify-center">
      <Label htmlFor={id} className="!text-xs font-semibold text-text-3">
        {label}
      </Label>
      <Button
        id={id}
        variant={ButtonVariant.Ghost}
        onClick={onClick}
        className={`text-sm font-normal text-text-1 hover:text-text-1 ${onClick != null ? 'cursor-pointer' : 'cursor-default'}`}
        label={info}
      />
    </div>
  )
}
