import { CheckLimitType } from '@flyward/knowledgeBase'
import { type CheckType, ComponentType, EnvironmentalImpactType, FlyToType, type HoursToCyclesRatioMrSettings } from '@flyward/platform'
import { type CheckTyeRateDTO } from '@flyward/platform/models/DTOs/CheckTyeRateDTO'
import { type CheckWithLimit } from '@flyward/platform/models/DTOs/CheckWithLimit'
import { type EngineUnitInfo } from '@flyward/platform/models/DTOs/EngineUnitInfo'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type CommonAssetDetails } from '@flyward/platform/models/DTOs/InterModuleComm/CommonAssetDetails'
import { type EnvImpactSettings, type DerateSettings, type FhFcRatioSettings } from '@flyward/platform/models/DTOs/KbSettings'
import { cloneDeep, isNil } from 'lodash'

export const handleInputChange = (
  flyForwardParameters: FlyForwardParametersDTO,
  field: keyof FlyForwardParametersDTO,
  value: string | number,
  resetReport: () => void,
  setFlyForwardParameters: ({ newFlyForwardParameters, resetDate }: { newFlyForwardParameters: FlyForwardParametersDTO; resetDate: boolean }) => void,
): void => {
  let newValue = value
  if (field === 'hoursToCyclesRatio') {
    const ratio = parseFloat(value.toString())
    newValue = ratio % 1 === 0 ? ratio.toString() : ratio.toFixed(2)
  }

  const oldValue = flyForwardParameters[field]

  if (String(oldValue) !== String(newValue)) {
    resetReport()
  }

  let newFlyForwardParameters: FlyForwardParametersDTO = {
    ...flyForwardParameters,
    [field]: newValue,
  }

  if (field === 'averageMonthlyFlightHours' || field === 'averageMonthlyFlightCycles') {
    const hours = newFlyForwardParameters.averageMonthlyFlightHours
    const cycles = newFlyForwardParameters.averageMonthlyFlightCycles
    newFlyForwardParameters = {
      ...newFlyForwardParameters,
      hoursToCyclesRatio: hours / cycles,
    }
  }

  const resetDate = field === 'flyToType' && value === FlyToType.Redelivery

  if (field === 'flyToType' && value === FlyToType.Redelivery) {
    newFlyForwardParameters = { ...newFlyForwardParameters, endDate: new Date().toString(), [field as keyof FlyForwardParametersDTO]: newValue }
  } else if (field === 'flyToType' && value === FlyToType.Custom) {
    newFlyForwardParameters = { ...newFlyForwardParameters, endDate: '', [field as keyof FlyForwardParametersDTO]: newValue }
  }

  setFlyForwardParameters({
    newFlyForwardParameters,
    resetDate,
  })
}

export const handleRateChange = (
  flyForwardParameters: FlyForwardParametersDTO,
  masterComponentSerialNumber: string,
  rateType: 'mrRates' | 'eolRates',
  checkType: CheckType,
  field: keyof CheckTyeRateDTO,
  value: number,
  resetReport: () => void,
  setFlyForwardParameters: ({ newFlyForwardParameters }: { newFlyForwardParameters: FlyForwardParametersDTO }) => void,
): void => {
  const newValue = value

  const componentRateToChange = flyForwardParameters.masterComponentsRates.find(
    (rate) => rate.masterComponentSerialNumber === masterComponentSerialNumber,
  )

  if (isNil(componentRateToChange)) {
    return
  }

  const unchangedComponentRates = flyForwardParameters.masterComponentsRates.filter(
    (rate) => rate.masterComponentSerialNumber !== masterComponentSerialNumber,
  )

  const checkTypeRateToSave = componentRateToChange[rateType].find((rate) => rate.checkType === checkType)

  if (isNil(checkTypeRateToSave)) {
    return
  }

  const oldValue = checkTypeRateToSave[field]

  if (oldValue !== newValue) {
    resetReport()
  }

  const checkTypeRateChanged = { ...checkTypeRateToSave, [field]: newValue }

  const unchangedCheckTypeRates = componentRateToChange[rateType].filter((rate) => rate.checkType !== checkType)

  const changedCheckTypeRates = [checkTypeRateChanged, ...unchangedCheckTypeRates].sort((a, b) => {
    if (a.checkType < b.checkType) return -1
    if (a.checkType > b.checkType) return 1
    return 0
  })

  const componentRateChanged = {
    ...componentRateToChange,
    [rateType]: changedCheckTypeRates,
  }

  const sortedRates = [componentRateChanged, ...unchangedComponentRates].sort((a, b) => {
    if (a.componentType < b.componentType) return -1
    if (a.componentType > b.componentType) return 1

    if (a.masterComponentSerialNumber > b.masterComponentSerialNumber) return -1
    if (a.masterComponentSerialNumber < b.masterComponentSerialNumber) return 1

    return 0
  })

  const newFlyForwardParameters: FlyForwardParametersDTO = {
    ...flyForwardParameters,
    masterComponentsRates: sortedRates,
  }

  setFlyForwardParameters({
    newFlyForwardParameters,
  })
}

export const handleEngineLimitsChange = (
  flyForwardParameters: FlyForwardParametersDTO,
  engineUnitId: string,
  kbProgramId: string,
  eprCheckLimitType: CheckLimitType,
  value: number,
  resetReport: () => void,
  setFlyForwardParameters: ({ newFlyForwardParameters }: { newFlyForwardParameters: FlyForwardParametersDTO }) => void,
): void => {
  const newValue = value

  let enginesDetails: EngineUnitInfo[] = flyForwardParameters.commonAssetDetails!.engineUnitInfos

  enginesDetails = cloneDeep(enginesDetails).sort((a, b) => {
    if (a.engineUnitSerialNumber < b.engineUnitSerialNumber) return -1
    if (a.engineUnitSerialNumber > b.engineUnitSerialNumber) return 1
    return 0
  })

  const engineDetailsToChange: EngineUnitInfo | undefined = enginesDetails.find((e) => e.engineUnitId === engineUnitId)

  if (isNil(engineDetailsToChange)) {
    return
  }

  const unchangedEnginesDetails = enginesDetails.filter((e) => e.engineUnitId !== engineUnitId)

  const eprKbCheckToChange: CheckWithLimit | undefined = engineDetailsToChange?.eprKbChecks.find(
    (kbCheck) =>
      kbCheck.programId === kbProgramId && kbCheck.componentType === ComponentType.EngineCore && kbCheck.eprCheckLimitType === eprCheckLimitType,
  )

  if (isNil(eprKbCheckToChange)) {
    return
  }

  const unchangedEprChecks = engineDetailsToChange?.eprKbChecks.filter(
    (kbCheck) =>
      kbCheck.programId !== kbProgramId ||
      (kbCheck.programId === kbProgramId && kbCheck.componentType !== ComponentType.EngineCore) ||
      (kbCheck.programId === kbProgramId && kbCheck.componentType === ComponentType.EngineCore && kbCheck.eprCheckLimitType !== eprCheckLimitType),
  )

  const eprKbCheckToSave = { ...eprKbCheckToChange, limitAmount: newValue }

  const oldValue = eprKbCheckToSave.limitAmount

  if (oldValue !== newValue) {
    resetReport()
  }

  const allEprChecks = [eprKbCheckToSave, ...(unchangedEprChecks ?? [])]

  const changedEngineDetails: EngineUnitInfo = {
    ...engineDetailsToChange,
    eprKbChecks: allEprChecks,
  }

  const newFlyForwardParameters = {
    ...flyForwardParameters,
    commonAssetDetails: !isNil(flyForwardParameters.commonAssetDetails)
      ? {
          ...flyForwardParameters.commonAssetDetails,
          engineUnitInfos: [...unchangedEnginesDetails, changedEngineDetails],
        }
      : undefined,
  }

  setFlyForwardParameters({
    newFlyForwardParameters,
  })
}

export const handleEngineLimitsEditMode = (
  flyForwardParameters: FlyForwardParametersDTO,
  engineUnitId: string,
  isInEditMode: boolean,
  resetReport: () => void,
  setFlyForwardParameters: ({ newFlyForwardParameters }: { newFlyForwardParameters: FlyForwardParametersDTO }) => void,
): void => {
  const newValue = isInEditMode

  let enginesDetails: EngineUnitInfo[] = flyForwardParameters.commonAssetDetails!.engineUnitInfos

  enginesDetails = cloneDeep(enginesDetails).sort((a, b) => {
    if (a.engineUnitSerialNumber < b.engineUnitSerialNumber) return -1
    if (a.engineUnitSerialNumber > b.engineUnitSerialNumber) return 1
    return 0
  })

  const engineToChange = flyForwardParameters.commonAssetDetails?.engineUnitInfos.find((e) => e.engineUnitId === engineUnitId)

  if (isNil(engineToChange)) {
    return
  }

  const unchangedEnginesDetails = enginesDetails.filter((e) => e.engineUnitId !== engineUnitId)

  const oldValue = engineToChange.areEngineLimitsInManualMode

  if (oldValue !== newValue) {
    resetReport()
  }

  const changedEngineDetails: EngineUnitInfo = {
    ...engineToChange,
    areEngineLimitsInManualMode: newValue,
  }

  const newFlyForwardParameters = {
    ...flyForwardParameters,
    commonAssetDetails: !isNil(flyForwardParameters.commonAssetDetails)
      ? {
          ...flyForwardParameters.commonAssetDetails,
          engineUnitInfos: [...unchangedEnginesDetails, changedEngineDetails],
        }
      : undefined,
  }

  setFlyForwardParameters({
    newFlyForwardParameters,
  })
}

export const handleOpEnvironmentChange = (
  flyForwardParameters: FlyForwardParametersDTO,
  value: EnvironmentalImpactType,
  resetReport: () => void,
  setFlyForwardParameters: ({ newFlyForwardParameters }: { newFlyForwardParameters: FlyForwardParametersDTO }) => void,
): void => {
  const newValue = value

  const oldValue = flyForwardParameters.commonAssetDetails?.assetDetailsSnapshot.operatingEnvironment

  if (String(oldValue) !== String(newValue)) {
    resetReport()
  }

  const oldAssetDetails: CommonAssetDetails | undefined = flyForwardParameters.commonAssetDetails

  let assetDetails: CommonAssetDetails | undefined

  if (!isNil(oldAssetDetails)) {
    assetDetails = {
      ...oldAssetDetails,
      assetDetailsSnapshot: { ...oldAssetDetails.assetDetailsSnapshot, operatingEnvironment: newValue },
    }
  }

  const newFlyForwardParameters: FlyForwardParametersDTO = {
    ...flyForwardParameters,
    commonAssetDetails: assetDetails,
  }

  setFlyForwardParameters({
    newFlyForwardParameters,
  })
}

export const getAssetComponentMrRate = (sortedRates: HoursToCyclesRatioMrSettings[], targetHoursToCycleRatio: number) => {
  return interpolateRatio(
    sortedRates.map((rate) => ({ ratio: rate.hoursToCycleRatio, value: rate.mrRate })),
    targetHoursToCycleRatio,
    0,
  )
}

export const getKbComponentRatioPct = (sortedRates: FhFcRatioSettings[], targetHoursToCycleRatio: number) => {
  return interpolateRatio(
    sortedRates.map((rate) => ({ ratio: rate.ratio, value: rate.limitAdjustmentPct })),
    targetHoursToCycleRatio,
  )
}

export const getKbComponentDeratePct = (sortedRates: DerateSettings[], targetHoursToCycleRatio: number) => {
  return interpolateRatio(
    sortedRates.map((rate) => ({ ratio: rate.deratePct, value: rate.derateAdjustmentPct })),
    targetHoursToCycleRatio,
  )
}

export const getKbComponentEnvAdjustment = (sortedRates: EnvImpactSettings[], environmentalImpactIdentifier: EnvironmentalImpactType) => {
  return sortedRates.find((rate) => rate.impactType === environmentalImpactIdentifier)?.percentage.toString() ?? '0'
}

const interpolateRatio = (sortedRates: Array<{ ratio: number; value: number }>, target: number, decimals: number = 2): string => {
  // if higher that all rates take the last
  if (target >= sortedRates[sortedRates.length - 1].ratio) {
    return sortedRates[sortedRates.length - 1].value.toString()
  }
  // if lower that all rates take the first
  if (target <= sortedRates[0].ratio) {
    return sortedRates[0].value.toString()
  }
  for (let i = 1; i < sortedRates.length; i++) {
    const rate = sortedRates[i]
    if (target <= rate.ratio) {
      return interpolateValue(target, sortedRates[i], sortedRates[i - 1], decimals)
    }
  }
  return sortedRates[0].value.toString()
}

const interpolateValue = (
  target: number,
  lowerLimit: { ratio: number; value: number },
  upperLimit: { ratio: number; value: number },
  decimals: number = 2,
): string => {
  const fcFhRatioDifference = upperLimit.ratio - lowerLimit.ratio
  const mrRateDifference = lowerLimit.value - upperLimit.value
  const differenceFromTargetValue = target - lowerLimit.ratio
  const percentageBetween = differenceFromTargetValue / fcFhRatioDifference
  const differenceBetweenRate = percentageBetween * mrRateDifference
  const mrRate = (lowerLimit.value - differenceBetweenRate).toFixed(decimals)

  return mrRate
}

export const getEngineLimitsDependencies = (
  engineKBProgramId: string,
  engineDetails: EngineUnitInfo,
  flyForwardParameters: FlyForwardParametersDTO,
) => {
  const kbFirstRunLimit = flyForwardParameters.assetKbChecks.find(
    (c) =>
      c.programId === engineKBProgramId &&
      c.componentType === ComponentType.EngineCore &&
      !isNil(c.eprCheckLimitType) &&
      c.eprCheckLimitType === CheckLimitType.FirstRun,
  )!
  const kbMatureLimit = flyForwardParameters.assetKbChecks.find(
    (c) =>
      c.programId === engineKBProgramId &&
      c.componentType === ComponentType.EngineCore &&
      !isNil(c.eprCheckLimitType) &&
      c.eprCheckLimitType === CheckLimitType.Mature,
  )!

  const engineDerateIdentifier = engineDetails.deratePct ?? 0
  const engineFhFcRatioIdentifier = flyForwardParameters.hoursToCyclesRatio!
  const environmentalImpactIdentifier: EnvironmentalImpactType =
    flyForwardParameters?.commonAssetDetails?.assetDetailsSnapshot?.operatingEnvironment ?? EnvironmentalImpactType.Benign

  const engineDerateSettings = engineDetails.eprKbChecks[0].derateSettings
  const engineFhFcSettings = engineDetails.eprKbChecks[0].fhFcRatioSettings
  const engineEnvironmentalImpactSettings = engineDetails.eprKbChecks[0].envImpactSettings

  const kbDerate = Number(getKbComponentDeratePct(engineDerateSettings, engineDerateIdentifier))
  const kbRate = Number(getKbComponentRatioPct(engineFhFcSettings, engineFhFcRatioIdentifier))
  const kbEnvAdjustment = Number(getKbComponentEnvAdjustment(engineEnvironmentalImpactSettings, environmentalImpactIdentifier))

  return { kbFirstRunLimit, kbMatureLimit, kbDerate, kbRate, kbEnvAdjustment }
}

export const computeEngineLimit = (engineKBProgramId: string, engineDetails: EngineUnitInfo, flyForwardParameters: FlyForwardParametersDTO) => {
  const { kbFirstRunLimit, kbMatureLimit, kbDerate, kbRate, kbEnvAdjustment } = getEngineLimitsDependencies(
    engineKBProgramId,
    engineDetails,
    flyForwardParameters,
  )

  const firstRunLimitComputed = Number(
    kbFirstRunLimit?.limitAmount * (1 + kbDerate / 100) * (1 + kbRate / 100) * (1 + kbEnvAdjustment / 100),
  ).toFixed(0)

  const matureLimitAmountComputed = Number(
    kbMatureLimit?.limitAmount * (1 + kbDerate / 100) * (1 + kbRate / 100) * (1 + kbEnvAdjustment / 100),
  ).toFixed(0)

  return { firstRunLimitComputed, matureLimitAmountComputed }
}
