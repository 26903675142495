import { type EnvironmentalImpactType, validateNullableDate, validateNullableNaturalNumber, validateRequiredDate } from '@flyward/platform'
import { z } from 'zod'

export interface StandAloneEngineDTO {
  leaseStartDate: string
  leaseEndDate: string
  dateOfLastTechSpec: string
  lessee: string
  lesseeHabitualBase: string
  operatingEnvironment: EnvironmentalImpactType
  region: string
  portfolio: string
}

export const StandaloneEngineDtoSchema = z.object({
  leaseStartDate: validateNullableDate,
  leaseEndDate: validateNullableDate,
  dateOfLastTechSpec: validateRequiredDate('Date of Last Tech Spec'),
  lessee: z.string().optional().nullable(),
  lesseeHabitualBase: z.string().optional().nullable(),
  operatingEnvironment: validateNullableNaturalNumber,
  region: z.string().optional().nullable(),
  portfolio: z.string().optional().nullable(),
})
