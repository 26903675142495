import { EditableInputCellWithLabelWithLabel, InputType, type TableColumn } from '@flyward/platform'
import { type DerateRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type Path, type Control, type FieldValues } from 'react-hook-form'

interface IDerateRatiosEditableTableProps<TFormData extends FieldValues> {
  derateRatios: DerateRatio[] | undefined
  formControl: Control<TFormData, unknown>
  tableClassName?: string
}
const tableColumns = <TFormData extends FieldValues>(formControl: Control<TFormData, unknown>): Array<TableColumn<DerateRatio>> => [
  {
    accessor: 'id',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'deratePct',
    title: 'Derate',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `derateRatios.${index}.deratePct` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'derateAdjustmentPct',
    title: 'Derate Adjustment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `derateRatios.${index}.derateAdjustmentPct` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]
export const DerateRatiosEditableTable = <TFormData extends FieldValues>({
  derateRatios,
  formControl,
  tableClassName = '',
}: IDerateRatiosEditableTableProps<TFormData>): React.ReactElement<IDerateRatiosEditableTableProps<TFormData>> => {
  return <MaintenanceProgramDetailsTable columns={tableColumns(formControl)} data={derateRatios} tableClassName={tableClassName} />
}
