import { type AirframeDto } from '@flyward/assets/models'
import { type AirframeLessorContributionDto } from '@flyward/assets/models/aircraftComponents/airframe/airframeLessorContributionDto'
import {
  AirframeCheckTypeDisplay,
  CnFormControl,
  CnFormField,
  CnFormItem,
  EditableInputCellWithLabelWithLabel,
  InputType,
  SimpleHeader,
} from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'

interface IAirframeLessorContributionsEditableTabProps {
  airframe: AirframeDto
  register: UseFormRegister<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
}

const generateGridColumns = (formControl: Control<AirframeDto, unknown>): Array<ColumnDef<AirframeLessorContributionDto>> => {
  const contractualReserveColumns: Array<ColumnDef<AirframeLessorContributionDto>> = [
    {
      id: 'checkType',
      accessorKey: 'checkType',
      header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => <div className="text-center text-xs">{AirframeCheckTypeDisplay(row.original.checkType)}</div>,
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="text-center text-xs">
          <CnFormField
            control={formControl}
            name={`lessorContributions.${row.index}.lessorContribution.isActive`}
            render={({ field }) => {
              return (
                <CnFormItem>
                  <CnFormControl>
                    <select
                      defaultValue={field.value?.toString() ?? 'false'}
                      onChange={(e) => {
                        const value = e.target.value === 'true'
                        field.onChange(value)
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </CnFormControl>
                </CnFormItem>
              )
            }}
          />
        </div>
      ),
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original.lessorContribution?.isActive === true) {
          return (
            <div className="text-center text-xs">
              <CnFormField
                control={formControl}
                name={`lessorContributions.${row.index}.lessorContribution.isUnlimited`}
                render={({ field }) => {
                  return (
                    <CnFormItem>
                      <CnFormControl>
                        <select
                          defaultValue={field.value?.toString() ?? 'false'}
                          onChange={(e) => {
                            const value = e.target.value === 'true'
                            field.onChange(value)
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </CnFormControl>
                    </CnFormItem>
                  )
                }}
              />
            </div>
          )
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original.lessorContribution?.isUnlimited !== true && row.original.lessorContribution?.isActive === true) {
          return (
            <div className="text-center text-xs">
              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                formControl={formControl}
                fieldName={`lessorContributions.${row.index}.lessorContribution.amount`}
              />
            </div>
          )
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24',
      },
    },
  ]

  return contractualReserveColumns
}

export const AirframeLessorContributionsEditableTab = ({ airframe, formControl }: IAirframeLessorContributionsEditableTabProps) => {
  const lessorContributions = airframe.lessorContributions

  const contractualReserveColumns = generateGridColumns(formControl)

  const contractualReserveTable = useReactTable<AirframeLessorContributionDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="w-100">
      <GenericTable {...contractualReserveTable} />
    </div>
  )
}
